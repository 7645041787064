const moment = require("moment-timezone");

const year = moment().year();

const NAME_SMARTELDS = "Smartelds";
const NAME_UNITY = "Unity";
const NAME_EB = "Eldbooks";
const NAME_BH = "Bluehorse";
const NAME_VS = "Vulcansols";
const NAME_RE = "Radical";
const NAME_AU = "Apex";
const NAME_RW = "Roadwise";
const NAME_NN = "Eld99";

const ANDROID_PACKAGE_SMARTELDS = "com.asrit.smartelds";
const IOS_PACKAGE_SMARTELDS = "smart-elds/id1578223541";

const ANDROID_PACKAGE_UNITY = "motion.com.eld";
const IOS_PACKAGE_UNITY = "motionseld/id6446702485";

const ANDROID_PACKAGE_EB = "com.eld.eldbooks";
const IOS_PACKAGE_EB = "eldbooks/id6476132106";

const ANDROID_PACKAGE_BH = "com.eld.bluehorse";
const IOS_PACKAGE_BH = "bluehorse-eld/id6479435773";

const ANDROID_PACKAGE_VS = "com.vulcansols.eld";
const IOS_PACKAGE_VS = "vulcansols/id6670468156";

const ANDROID_PACKAGE_RE = "com.radical.eld";
const IOS_PACKAGE_RE = "radical/123";

const ANDROID_PACKAGE_AU = "com.apexeld.eld";
const IOS_PACKAGE_AU = "apex-ultima/id6736353183";

const ANDROID_PACKAGE_RW = "com.roadwise.eld";
const IOS_PACKAGE_RW = "roadwiseeld/123";

const ANDROID_PACKAGE_NN = "com.firefly.eld99";
const IOS_PACKAGE_NN = "eld99/123";

const appNames = {
  Smartelds: "SE",
  Unity: "ME",
  Eldbooks: "EB",
  Bluehorse: "BH",
  Vulcansols: "VS",
  Radical: "RE",
  Apex: "AU",
  Roadwise: "RW",
  Eld99: "NN",
};

const isSmartelds = process.env.REACT_APP_CLIENT_APP === NAME_SMARTELDS;
const isUnity = process.env.REACT_APP_CLIENT_APP === NAME_UNITY;
const isEldBooks = process.env.REACT_APP_CLIENT_APP === NAME_EB;
const isBluehorse = process.env.REACT_APP_CLIENT_APP === NAME_BH;
const isVulcansols = process.env.REACT_APP_CLIENT_APP === NAME_VS;
const isRadical = process.env.REACT_APP_CLIENT_APP === NAME_RE;
const isApex = process.env.REACT_APP_CLIENT_APP === NAME_AU;
const isRoadwise = process.env.REACT_APP_CLIENT_APP === NAME_RW;
const isEldNN = process.env.REACT_APP_CLIENT_APP === NAME_NN;

const selApp = appNames[process.env.REACT_APP_CLIENT_APP];

let andriodPackage = ANDROID_PACKAGE_SMARTELDS;
let iosPackage = IOS_PACKAGE_SMARTELDS;

let name = "Smartelds";
let email = "support@smartelds.com";
let companyName = "Asritsolutions LLC";
let website = "https://www.smartelds.com";

if (isUnity) {
  andriodPackage = ANDROID_PACKAGE_UNITY;
  iosPackage = IOS_PACKAGE_UNITY;
  name = "Motion ELD";
  website = "https://motioneld.com";
  companyName = "Motion ELD LLC";
  email = "support@motioneld.com";
}
if (isEldBooks) {
  andriodPackage = ANDROID_PACKAGE_EB;
  iosPackage = IOS_PACKAGE_EB;
  name = "Eldbooks";
  website = "https://eldbooks.com";
  companyName = "Asritsolutions LLC";
  email = "support@eldbooks.com";
}
if (isBluehorse) {
  andriodPackage = ANDROID_PACKAGE_BH;
  iosPackage = IOS_PACKAGE_BH;
  name = "Bluehorseeld";
  website = "https://bluehorseeld.com";
  companyName = "Bluehorseeld Inc";
  email = "support@bluehorseeld.com";
}

if (isVulcansols) {
  andriodPackage = ANDROID_PACKAGE_VS;
  iosPackage = IOS_PACKAGE_VS;
  name = "Vulcansols ELD";
  website = "http://vulcansols.com";
  companyName = "Vulcansols Inc";
  email = "support@vulcansols.com";
}

if (isRadical) {
  andriodPackage = ANDROID_PACKAGE_RE;
  iosPackage = IOS_PACKAGE_RE;
  name = "Radical ELD";
  website = "www.radicaleld.com";
  companyName = "Radical ELD LLC";
  email = "support@radicaleld.com";
}

if (isApex) {
  andriodPackage = ANDROID_PACKAGE_AU;
  iosPackage = IOS_PACKAGE_AU;
  name = "Apex Ultima";
  website = "www.apexeld.com";
  companyName = "Apex ELD LLC";
  email = "support@apexeld.com";
}

if (isRoadwise) {
  andriodPackage = ANDROID_PACKAGE_RW;
  iosPackage = IOS_PACKAGE_RW;
  name = "Roadwise ELD";
  website = "www.roadwiseeld.com";
  companyName = "Roadwise ELD Inc";
  email = "support@roadwiseeld.com";
}

if (isEldNN) {
  andriodPackage = ANDROID_PACKAGE_NN;
  iosPackage = IOS_PACKAGE_NN;
  name = "Eld99";
  website = "www.eld99.com";
  companyName = "FireFly Systems LLC";
  email = "info@eld99.com";
}

const andriodUrl = `https://play.google.com/store/apps/details?id=${andriodPackage}`;
const iosUrl = `https://apps.apple.com/us/app/${iosPackage}`;
const isQueueWait = isSmartelds || isEldBooks;
export {
  isSmartelds,
  isUnity,
  isEldBooks,
  isBluehorse,
  isVulcansols,
  isRadical,
  isApex,
  isRoadwise,
  isEldNN,
  selApp,
  andriodUrl,
  iosUrl,
  isQueueWait,
  year,
  name,
  email,
  website,
  companyName,
};
