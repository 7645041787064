import React from "react";
import _get from "lodash/get";
import html2pdf from "html2pdf.js";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Formik, Form } from "formik";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import CheckBox from "../../components/CheckBox";
import InputDateBox from "../../components/InputDateBox";
import {
  getDriverDvirTemplates,
  getDriverSignById,
  createDriverDvir,
} from "../../actions/dvirActions";
import {
  formatDateTime,
  formatDate,
  formatTime,
  formatDateTimeToUtc,
} from "../../actions/momentActions";
import { pdfOptions } from "../../helpers/helpers";
import Error from "../Error";
import Success from "../Success";
import { defaultValues, validate } from "./validate";

const styles = (theme) => ({
  wrapper: {},
  table: { border: "1px solid #ccc", marginBottom: 25 },
  DialogActions: {
    marginTop: 40,
    justifyContent: "center",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const inspectionTypes = [
  { name: "Posttrip", id: "postTrip" },
  { name: "Pretrip", id: "preTrip" },
  { name: "Ad hoc", id: "adHoc" },
];

const vehicleTypes = [
  { name: "Tractor", id: "tractor" },
  { name: "Trailer", id: "trailer" },
  { name: "TractorTrailer", id: "tractortrailer" },
  { name: "Bus", id: "bus" },
];

// const inspectorTypes = [
//   { name: "Driver", id: "driver" },
//   { name: "Mechanic", id: "mechanic" },
//   { name: "Other", id: "other" },
// ];

const equipmentConditions = [
  { name: "Defects corrected", id: "defectsCorrected" },
  { name: "Un satisfactory", id: "unSatisfactory" },
  { name: "Satisfactory", id: "satisfactory" },
  { name: "Nodefect", id: "noDefect" },
];

export class AddDvirDialog extends React.Component {
  state = {
    email: "",
    carrier: null,
    successMessage: "",
    errorMessage: "",
    loading: false,
    forms: [],
    templatesOrg: [],
    areas: [],
    signData: null,
  };

  componentDidMount() {
    this.loadSign();
    this.loadTemplates();
  }

  loadSign = async (e) => {
    const driverId = _get(this, "props.selected.driverId");
    try {
      const response = await this.props.getDriverSignById({ driverId });
      if (response.data) {
        this.setState({ signData: response.data });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };

  loadTemplates = async (e) => {
    try {
      const response = await this.props.getDriverDvirTemplates({});
      if (response && Array.isArray(response)) {
        //  for unique indexing for areas
        const templatesWithUniqueIndex = response.map((template) => {
          const uniqueAreas = template.areas.map((area, idx) => ({
            ...area,
            index: idx + 1,
          }));
          // console.log("templatesUniqueIndex:::::____", uniqueAreas)
          return { ...template, areas: uniqueAreas };
        });
   
        this.setState({ templatesOrg: templatesWithUniqueIndex });
      }
    } catch (e) {
      console.log("e :::: ", e);
    }
  };
  

  createPdf = async () => {
    try {
      this.setState({ loading: true });
      const vehicleName = _get(this, "props.selected.vehicleName");
      const insType = _get(this, "props.selected.inspectionType");
      const _startTime = _get(this, "props.selected.startTime");
      const startTime = this.props.formatDateTime(_startTime);
      const fileName = `dvir-${vehicleName}-${insType}-${startTime}.${"pdf"}`.replace(
        / /g,
        ""
      );

      const element = document.getElementById("dvirPdf");

      pdfOptions.filename = fileName;
      pdfOptions.margin = [0.3, 0.1];
      await html2pdf().set(pdfOptions).from(element).toPdf().save();
      this.setState({ loading: false });
    } catch (e) {
      this.setState({ loading: false });
      console.log(e);
    }
  };

  onSubmit = async (values) => {
    const _ar = values.ar;
    const _areas = this.state.areas || [];
    const areas = _areas.map((item, idx) => {
      return {
        media: [],
        name: item.name,
        note: "",
        value: _ar[idx + 1] ? 1 : 0,
      };
    });

    const timezone = this.props.timezone;
    const selVehicle = this.props.selVehicle || {};
    const selTrailer = this.props.selTrailer || {};
    const profile = this.props.profile || {};
    const user = profile.user || {};
    const payload = {
      media: {
        folder: "dvir",
        name: "",
        bucket: "",
      },
      status: "ACTIVE",
      coordinates: [0, 0],
      inspectorType: "driver",
      location: values.location,
      odometer: values.odometer,
      equipmentCondition: values.equipmentCondition,
      inspectionType: values.inspectionType,
      vehicleType: values.vehicleType,
      templateId: values.form,
      areas: areas,
      driverId: user.driverId,
      driverName: `${user.firstName} ${user.lastName}`,
      accountId: user.accountId,
      date: values.startTime,
      startTime: this.props.formatDateTimeToUtc(values.startTime, timezone),
      endTime: this.props.formatDateTimeToUtc(values.endTime, timezone),
    };

    if (values.vehicleType === "tractor") {
      payload.vehicleId = selVehicle.id;
      payload.vehicleName = selVehicle.name;
    }
    if (values.vehicleType === "trailer") {
      payload.vehicleId = selTrailer.id;
      payload.vehicleName = selTrailer.name;
    }
    if (values.vehicleType === "bus") {
      payload.vehicleId = selVehicle.id;
      payload.vehicleName = selVehicle.name;
    }
    else if (values.vehicleType === "tractortrailer") {
      payload.vehicleId = selVehicle.id;
      payload.vehicleName = selVehicle.name;
      payload.trailerId = selTrailer.id;
      payload.trailerName = selTrailer.name;
    }
    
    try {
      this.setState({ successMessage: "", errorMessage: "", loading: true });
      const response = await this.props.createDriverDvir(payload);
      if (response && response._id) {
        this.setState({
          successMessage: "Saved successfully.",
          loading: false,
        });
        setTimeout(() => this.props.afterSave(), 1000);
      } else {
        this.setState({ errorMessage: "Not able to saved.", loading: false });
      }
    } catch (e) {
      this.setState({ errorMessage: "Not able to saved.", loading: false });
    }
  };

  render() {
    const { classes, selVehicle, selTrailer } = this.props;
    const { errorMessage, successMessage, loading } = this.state;
    const { signData, showSign } = this.state;
    const areas = this.state.areas || [];

    return (
      <Dialog
        fullScreen
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" onClose={this.props.onClose}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}></Grid>
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
              {"Add dvir"}
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ textAlign: "right", padding: 0 }}>
                <Button size="small" onClick={this.props.handleClose}>
                  {"Close"}
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <div style={{ maxWidth: "100%", margin: "auto" }} id={"dvirPdf"}>
            <h3 style={{ textAlign: "center" }}>
              DRIVER'S VEHICLE INSPECTION REPORT (DVIR)
            </h3>
            <h5 style={{ textAlign: "center" }}>
              AS REQUIRED BY THE D.O.T. FEDERAL MOTOR CARRIER SAFETY REGULATIONS
            </h5>
            <div>
              <Formik
                initialValues={defaultValues}
                validate={validate}
                onSubmit={this.onSubmit}
                enableReinitialize={true}
              >
                {({ values, handleSubmit, setFieldValue, errors, ...rest }) => {
                  return (
                    <Form style={{ padding: "20px 10px" }}>
                      <Grid container spacing={3}>
                        <Grid md={12} item>
                          {values.vehicleType === "tractor" && (
                            <div>
                              {"Tractor# "}
                              {selVehicle.name}
                            </div>
                          )}
                          {values.vehicleType === "trailer" && (
                            <div>
                              {"Trailer# "}
                              {selTrailer.name}
                            </div>
                          )}
                          {values.vehicleType === "tractortrailer" && (
                            <div>
                              <div>{"Tractor# "}{selVehicle.name}</div>
                              <div>{"Trailer# "}{selTrailer.name}</div>
                            </div>
                          )}
                           {values.vehicleType === "bus" && (
                            <div>
                              <div>{"Tractor# "}{selVehicle.name}</div>
                            </div>
                          )}
                        </Grid>
                        <InputDateBox
                          md={3}
                          type={'datetime-local'}
                          name="startTime"
                          label="Start Date"
                          format={"MM/DD/yyyy hh:mm:ss A"}
                          maxDate={new Date()}
                        />
                        <InputDateBox
                          md={3}
                          type={'datetime-local'}
                          name="endTime"
                          label="End Date"
                          format={"MM/DD/yyyy hh:mm:ss A"}
                          maxDate={new Date()}
                        />
                        <SelectBox
                          md={2}
                          name="inspectionType"
                          label="Inspection type"
                          items={inspectionTypes}
                        />
                        <SelectBox
                          md={2}
                          name="vehicleType"
                          label="Vehicle type"
                          items={vehicleTypes}
                          onChange={(e) => {
                            const _id = e.target.value;
                            const _org = this.state.templatesOrg || [];
                            let _forms = [];
                            if (_id === "tractortrailer") {
                              _forms = _org.filter((item) => item.name === "TractorTrailer");
                            } else {
                              _forms = _org.filter((item) =>
                                item.assetType.includes(_id.toUpperCase())
                              );
                            }
                            this.setState({ forms: _forms });
                          }}
                        />
                        <SelectBox
                          md={2}
                          name="form"
                          label="Form"
                          selValue={"name"}
                          selLabel={"name"}
                          items={this.state.forms}
                          onChange={(e) => {
                            const _id = e.target.value;
                            const _forms = this.state.forms || [];
                            const _obj = _forms.find((_i) => _i.name === _id);
                            this.setState({ areas: _obj.areas });
                          }}
                        />

                        <Grid
                          sm={12}
                          md={12}
                          item
                          style={{
                            padding: 14,
                            marginTop: 20,
                          }}
                        >
                          {values?.form && (
                              <div 
                              style={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center"
                              }}
                              > 
                              <h4>Inspection Area</h4>
                              <Button
                             variant="contained"
                              color="primary"
                              size="small"
                              onClick={()=>{
                                const newSelectAll=!this.state.selectAll
                                this.setState({selectAll:newSelectAll})
                                areas.forEach((item)=>{
                                setFieldValue(`ar.${item.index}`,newSelectAll ? 1:0)
                                })
                              }}
                              >
                             {this.state.selectAll ? "Deselect All" : "Select All"}
                              </Button>
                              </div>
                          )}
                          <Grid container>
                            {areas &&
                              areas.map((item) => {
                                return (
                                  <Grid
                                    item
                                    sm={12}
                                    md={3}
                                    key={`key-${item.index}`}
                                  >
                                  <CheckBox
                                    md={12}
                                    name={`ar.${item.index}`}  
                                    label={`${item.name}`}
                                    onChange={(e) => {
                                      const value = e.target.checked ? 1 : 0;
                                      // Dynamically setting the value based on whether checkbox is checked
                                      setFieldValue(`ar.${item.index}`, value);
                                    }}
                                  />
                                </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>

                        <SelectBox
                          md={6}
                          name="equipmentCondition"
                          label="Equipment conditions"
                          items={equipmentConditions}
                          onChange={(e) => {}}
                        />
                        <InputBox md={3} name="location" label="Location" />
                        <InputBox md={3} name="odometer" label="Odometer" />
                      </Grid>
                      <div style={{ textAlign: "center", marginTop: 45 }}>
                        <h5>
                          I declare that this asset has been inspected in
                          accordance with the applicable requirements.
                        </h5>
                        <Grid container spacing={0}>
                          {!showSign && (
                            <Grid item xs={12} md={12}>
                              <Button
                                size={"small"}
                                variant="contained"
                                onClick={() => {
                                  this.setState({ showSign: true });
                                  setFieldValue("signature", "done");
                                }}
                              >
                                {"Accept & Sign"}
                              </Button>
                            </Grid>
                          )}
                          <Grid item xs={12} md={12}>
                            <div>
                              {signData && showSign && (
                                <img
                                  src={`data:image/jpeg;base64,${signData}`}
                                  style={{ width: 120 }}
                                  alt="Signature"
                                />
                              )}
                            </div>
                          </Grid>
                          {showSign && (
                            <Grid item xs={12} md={12}>
                              <h5>Driver Signature</h5>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                      {successMessage && <Success message={successMessage} />}
                      {errorMessage && <Error message={errorMessage} />}

                      {errors.startTime && (
                        <Error message={"Date is required"} />
                      )}
                      {errors.signature && (
                        <Error message={"Signature is required"} />
                      )}
                      <div className={classes.DialogActions}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={loading}
                        >
                          {loading ? "Saving..." : "SAVE"}
                        </Button>{" "}
                        <Button
                          variant="contained"
                          onClick={this.props.handleClose}
                        >
                          {"CANCEL"}
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    timezone: _get(state, "appData.timezone", ""),
    loading: _get(state, "authData.termsBusy", false),
    constants: _get(state, "appData.constants", {}),
    errorMessage: _get(state, "authData.errorMessage", ""),
    profile: _get(state, "authData.profile", {}),
    selVehicle: _get(state, "authData.profile.vehicle", {}),
    selTrailer: _get(state, "authData.profile.trailer", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getDriverSignById,
      getDriverDvirTemplates,
      createDriverDvir,
      formatDateTime,
      formatTime,
      formatDate,
      formatDateTimeToUtc
    },
    dispatch
  );

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AddDvirDialog))
);
