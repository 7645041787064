import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import { formatCurrency } from "../../../utils/numberHelpers";
import { formatTimeSpan } from "../../../utils/momentHelpers";
import LaunchIcon from "@mui/icons-material/Launch";

const styles = (theme) => ({
  paper: {
    margin: 15,
    overflow: "hidden",
  },
  contentWrapper: {
    marginTop: "15px",
  },
});

function AccountBilling(props) {
  const classes = props.classes;
  const accountId = _get(props, "match.params.accountId");
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(0);

  const [payments, setPayments] = useState([]);

  async function getData(_limit, starting_after, ending_before) {
    let payload = { limit: limit, customer: props.stripeBuss };

    if (_limit) {
      payload.limit = _limit;
    }

    if (starting_after) {
      payload.starting_after = starting_after;
    }

    if (ending_before) {
      payload.ending_before = ending_before;
    }

    setLoading(true);
    setPayments([]);
    const response = await props.getBillPayment(payload);
    const _payments = _get(response, "payments.data", []);
    const charges = _get(response, "charges.data", []);

    const payments = _payments.map((item) => {
      item.charge = charges.find((charge) => {
        return charge.payment_intent === item.id;
      });
      return item;
    });

    setPayments(payments);
    setLoading(false);
  }

  function handleUrl(e, url) {
    e.preventDefault();
    window.open(url, "_blank");
  }

  useEffect(() => {
    getData();
  }, []);

  async function onRowsPerPageChange(e, params) {
    setLimit(e.target.value);
    getData(e.target.value);
  }

  async function onPageChange(e, _page) {
    const starting_after =
      payments[payments.length - 1] && payments[payments.length - 1].id;
    const ending_before = payments[0] && payments[0].id;
    setPage(_page);
    if (_page > page) {
      getData(limit, starting_after);
    } else {
      getData(limit, null, ending_before);
    }
  }

  // console.log("payments ::: ", payments);
  return (
    <Paper className={classes.paper}>
      <h2>Payments</h2>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Status</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Pdf</TableCell>
          </TableRow>
          {payments &&
            payments.map((item) => {
              const charge = item.charge || {};
              const amount = item.amount ? parseFloat(item.amount) / 100 : 0;
              let created = item.created;

              if (charge && charge.created) {
                created = charge.created;
              }

              return (
                <TableRow key={item.id}>
                  <TableCell style={{ textTransform: "capitalize" }}>
                    {item.status}
                  </TableCell>
                  <TableCell>
                    {item.description}
                    <div>{item.receipt_email}</div>
                  </TableCell>
                  <TableCell>
                    <div>{formatCurrency(amount)}</div>
                  </TableCell>
                  <TableCell>{formatTimeSpan(created)}</TableCell>
                  <TableCell>
                    <LaunchIcon
                      style={{ color: "#009ce5", cursor: "pointer" }}
                      onClick={(e) => handleUrl(e, charge.receipt_url)}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
        </TableHead>
        <TableBody></TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={-1}
        rowsPerPage={limit}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  );
}

export default withStyles(styles)(AccountBilling);
