import React, { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from "@mui/material";
import html2pdf from "html2pdf.js";

function RenderTableRows({ item, index }) {
  return (
    <TableRow hover style={{ cursor: "pointer" }} key={index}>
      <TableCell style={{ textTransform: "capitalize" }}>{item.alias || ""}</TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>{item.firstName || ""}</TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>{item.lastName || ""}</TableCell>
      <TableCell>{item.email || ""}</TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>{item.userStatus || ""}</TableCell>
      <TableCell>{item.licenseNumber || ""}</TableCell>
      <TableCell>{item.licenseState || ""}</TableCell>
    </TableRow>
  );
}

export default function RenderTable({ drivers = [] }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [downloading, setDownloading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const tableRef = useRef(null);

  const handleChange = () => {
    setShowAll((prev) => !prev);
  };

  const filterDriver = drivers.filter((driver) => {
    const firstName = driver?.firstName?.toLowerCase() || '';
    const lastName = driver?.lastName?.toLowerCase() || '';
    const email = driver?.email?.toLowerCase() || '';
    const licenseNumber = driver?.licenseNumber?.toLowerCase() || '';
    const licenseState = driver?.licenseState?.toLowerCase() || '';
    const search = searchQuery.toLowerCase();

    const matchesSearch =
      firstName.includes(search) ||
      lastName.includes(search) ||
      email.includes(search) ||
      licenseNumber.includes(search) ||
      licenseState.includes(search);

    const isActive = driver.userStatus?.toLowerCase() === "active";

    return showAll ? matchesSearch : matchesSearch && isActive;
  });

  async function download() {
    try {
      setDownloading(true);
      const element = tableRef.current;

      if (!element) {
        console.error("Element with ID 'Drivers-report' not found.");
        setDownloading(false);
        return;
      }

      const pdfOptions = {
        margin: [0.1, 0.5, 0.5, 0.1],
        filename: "Drivers-Reports.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 3,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
          windowWidth: element.scrollWidth,
        },
        jsPDF: {
          unit: "mm",
          format: "letter",
          orientation: "landscape",
          compressPDF: true,
        },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      };

      await html2pdf().set(pdfOptions).from(element).save();
    } catch (error) {
      console.error("Error while downloading PDF:", error);
    } finally {
      setDownloading(false);
    }
  }

  return (
    <>
      <div style={{
        textAlign: "right",
        display: 'flex',
        justifyContent: "space-between",
        gap: "10px"
      }}>
        <FormGroup style={{ textAlign: "left" }}>
          <FormControlLabel
            control={
              <Switch
                checked={showAll}
                onChange={handleChange}
                color="primary"
              />
            }
            label="Show All"
          />
        </FormGroup>
        <div>
          <TextField
            label="Search..."
            variant="outlined"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            size="small"
            inputProps={{
              style: { fontSize: "12px", height: "14px" },
            }}
            InputLabelProps={{
              style: { fontSize: "12px" },
            }}
          />
          <Button
            onClick={download}
            size="small"
            variant="contained"
            style={{
              fontSize: "12px",
              minWidth: "auto",
              marginLeft: "5px"
            }}
          >
            {downloading ? "Downloading..." : "Download"}
          </Button>
        </div>
      </div>

      <Grid item xs={12} md={12} lg={12} ref={tableRef}>
        <Typography
          variant="h5"
          component="h2"
          style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
        >
          Drivers Details
        </Typography>

        <TableContainer component={Paper} style={{ overflowX: "auto" }}>
          <Table size="small" sx={{ minWidth: "100%", tableLayout: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Driver ID</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>First Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Last Name</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Email</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>License Number</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>License State</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterDriver.length > 0 ? (
                filterDriver.map((driver, idx) => (
                  <RenderTableRows key={`driver-${idx}`} item={driver} index={idx} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    No data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
