import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const styles = (theme) => ({});

const reports = [
  {
    name: "Duty Status",
    url: "duty-status",
    tag: "HOS",
    detail: "Daily duty status for driver's with graph",
    download: "PDF",
  },
  {
    name: "Duty Logs",
    url: "duty-logs",
    tag: "HOS",
    detail: "Duty logs for driver's",
    download: "PDF",
  },
  {
    name: "HOS Recap",
    url: "hos-recap",
    tag: "COMPLIANCE",
    detail: "Report of HOS recaps for drivers",
    pdf: true,
    download: "PDF",
  },
  {
    name: "State Mileage",
    url: "state-mileage",
    tag: "COMPLIANCE",
    detail: "Report of state mileage for equipments",
    pdf: true,
    download: "PDF",
  },
  {
    name: "Drivers Detail",
    url: "drivers-detail",
    tag: "ASSET",
    detail: "Details of all drivers",
    pdf: true,
    download: "PDF",
  },

  {
    name: "Vehicle Detail",
    url: "vehicle-detail",
    tag: "ASSET",
    detail: "Details of all vehicles",
    pdf: true,
    download: "PDF",
  },

  {
    name: "GPS Detail",
    url: "gps-detail",
    tag: "ASSET",
    detail: "Details of all GPS locations",
    download: "CSV",
  },
  {
    name: "Duty Logs History",
    url: "duty-history",
    tag: "HOS",
    detail: "Archived duty logs",
    download: "CSV",
    isSupport: true,
  },

  // {
  //   name: "Drivers Detail",
  //   url: "drivers-detail",
  //   tag: "ASSET",
  //   detail: "Details of all drivers",
  // },
  //
  // {
  //   name: "Vehicle Detail",
  //   url: "vehicle-detail",
  //   tag: "ASSET",
  //   detail: "Details of all vehicles",
  // },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function Report(props) {
  const accountBaseUrl = useSelector((state) => state.authData.accountBaseUrl);
  const adminId = useSelector((state) => state.authData.profile.adminId);

  function handleClick(url) {
    props.history.push(`${accountBaseUrl}/reports/${url}`);
  }

  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <Grid container spacing={2}>
        {reports.map((item, idx) => {
          if (item.isSupport) {
            if (!adminId) {
              return null;
            }
          }

          return (
            <Grid
              style={{
                minHeight: 100,
              }}
              item
              xs={12}
              md={6}
              key={`id-${idx}-${item.url}`}
            >
              <Paper elevation={2} onClick={() => handleClick(item.url)}>
                <div
                  style={{
                    minHeight: 100,
                    padding: 25,
                    textAlign: "left",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  <Typography variant="h5" component="h2">
                    {item.name}
                    <div style={{ float: "right", fontSize: 12 }}>
                      {item.download}
                    </div>
                  </Typography>
                  <Typography variant="p" component="p">
                    {item.detail}
                  </Typography>
                </div>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withRouter(Report);
