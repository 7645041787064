import React from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@mui/styles";
import { getAllUsers } from "../../actions/adminDashActions";
import { getResellerById } from "../../actions/reseller/resellerActions";
import { getAccounts } from "../../actions/account/accountAdminActions";
import { formatDate } from "../../actions/momentActions";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
// import CardActionArea from "@mui/material/CardActionArea";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { LABELS as _LABELS } from "../../language";
// import CircularProgress from '@mui/material/CircularProgress';
// import { formatDate } from "../../actions/momentActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  root: {
    margin: 15,
  },
  title: {
    textAlign: "center",
    textTransform: "uppercase",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  statusButton: {
    margin: "0 2vh 0 2vh",
    float: "right",
  },
});

export class ResellerAccounts extends React.Component {
  render() {
    const classes = this.props.classes;
    const accounts = this.props.accounts;

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{"Name"}</TableCell>
              <TableCell>{"Status"}</TableCell>
              <TableCell>{"Detail"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {accounts &&
              accounts.map((account) => (
                <TableRow key={account._id}>
                  <TableCell component="th" scope="user">
                    {account.name}
                  </TableCell>
                  <TableCell align="left">{account.status}</TableCell>
                  <TableCell align="left">
                    {account.email}
                    <div>{account.contact && account.contact.name}</div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default ResellerAccounts;
