import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import html2pdf from "html2pdf.js";
import _get from "lodash/get";

function RenderTableRows({ item, index }) {
  const status = item.active ? "ACTIVE" : "INACTIVE";

  return (
    <TableRow hover style={{ cursor: "pointer" }} key={index}>
      <TableCell style={{ textTransform: "capitalize" }}>
        {item.name || " "}
      </TableCell>
      <TableCell>{item.vin || ' '}</TableCell>
      <TableCell>{item.license.plate || 'N/A'}</TableCell>
      <TableCell>{item.license.state || 'N/A'}</TableCell>
      <TableCell >
        {status}
      </TableCell>
    </TableRow>
  );
}

export default function RenderTable({ vehicles = [] }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [downloading, setDownloading] = useState(false);
   const [showAll, setShowAll] = useState(false);

  // Filter only VEHICLE types and apply search filter
  const filteredVehicles = vehicles.filter((vehicle) => {
    if (vehicle.assetType !== "VEHICLE") return false;
    const vehicleName = vehicle?.name?.toLowerCase() || '';
    const vin = vehicle?.vin?.toLowerCase() || '';
    const licenseState = vehicle?.license?.state?.toLowerCase() || '';
    const search = searchQuery.toLowerCase();
   
    const matchesSearch=
     vehicleName.includes(search) ||
     vin.includes(search) ||
     licenseState.includes(search) ;
     const isActive = vehicle.active;

     return showAll ? matchesSearch : matchesSearch && isActive;
  });

  const handleChange = () => {
    setShowAll((prev) => !prev);
  };

  async function download() {
    try {
      setDownloading(true);
      const element = document.getElementById("All-Vehicle-report");

      if (!element) {
        console.error("Element with ID 'All-Vehicle-report' not found.");
        setDownloading(false);
        return;
      }
      const pdfOptions = {
        margin: [10, 10, 10, 10],
        filename: "Vehicle-Details.pdf",
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          scale: 3,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
          windowWidth: element.scrollWidth, 
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "landscape",
          compressPDF: true,
        },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] }, 
      };

      await html2pdf().set(pdfOptions).from(element).save();
    } catch (error) {
      console.error("Error while downloading PDF:", error);
    } finally {
      setDownloading(false);
    }
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
        }}
      > 
       <FormGroup style={{ textAlign: "left" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showAll}
                      onChange={handleChange}
                      color="primary"
                    />
                  }
                  label="Show All"
                />
              </FormGroup>
              <div>
              <TextField
          id="standard-basic"
          label="Search..."
          variant="outlined"
          size="small"
          inputProps={{ style: { fontSize: "12px", height: "14px"} }}
          InputLabelProps={{ style: { fontSize: "12px" } }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <Button
          onClick={download}
          size="small"
          variant="contained"
          style={{ fontSize: "12px", minWidth: "auto", marginLeft:"5px"}}
        >
          {downloading ? "Downloading..." : "Download"}
        </Button>
              </div>
      
      </div>

      <Grid item xs={12} md={12} lg={12} id="All-Vehicle-report">
        <Typography
          variant="h5"
          component="h2"
          style={{ textAlign: "center", marginTop: 40, marginBottom: 40 }}
        >
          Vehicle Details
        </Typography>
        <TableContainer component={Paper}>
          <Table size="small" sx={{ minWidth: 650 }} aria-label="vehicle table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: "bold" }}>Unit Number</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>VIN</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>License Plate</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>State</TableCell>
                <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredVehicles.length > 0 ? (
                filteredVehicles.map((vehicle, idx) => (
                  <RenderTableRows key={`vehicle-${idx}`} item={vehicle} index={idx} />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: "center" }}>
                    No vehicle data found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
}
