import axios from "./axios";
import _get from "lodash/get";

async function _getBillCustomer(BASE_URL, params) {
  try {
    const response = await axios({
      url: `${BASE_URL}/admin/bill/customer`,
      method: "get",
      params,
    });
    return _get(response, "data", []);
  } catch (e) {
    return [];
  }
}

export function getAllBillCustomer(params = {}) {
  return async (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");

    let response2 = [];
    let response3 = [];
    let response4 = [];
    let response5 = [];
    let response6 = [];

    const response1 = await _getBillCustomer(BASE_URL, { limit: 100 });

    let response = [...response1.data];

    if (response1.has_more) {
      const _last = response1.data && response1.data[response1.data.length - 1];
      const _lastId = _last && _last.id;
      response2 = await _getBillCustomer(BASE_URL, {
        limit: 100,
        starting_after: _lastId,
      });
      if (response2.data) {
        response = [...response1.data, ...response2.data];
      }
    }

    if (response2.has_more) {
      const _last = response2.data && response2.data[response2.data.length - 1];
      const _lastId = _last && _last.id;
      response3 = await _getBillCustomer(BASE_URL, {
        limit: 100,
        starting_after: _lastId,
      });
      if (response3.data) {
        response = [...response1.data, ...response2.data, ...response3.data];
      }
    }

    if (response3.has_more) {
      const _last = response3.data && response3.data[response3.data.length - 1];
      const _lastId = _last && _last.id;
      response4 = await _getBillCustomer(BASE_URL, {
        limit: 100,
        starting_after: _lastId,
      });
      if (response4.data) {
        response = [
          ...response1.data,
          ...response2.data,
          ...response3.data,
          ...response4.data,
        ];
      }
    }

    if (response4.has_more) {
      const _last = response4.data && response4.data[response4.data.length - 1];
      const _lastId = _last && _last.id;
      response5 = await _getBillCustomer(BASE_URL, {
        limit: 100,
        starting_after: _lastId,
      });
      if (response5.data) {
        response = [
          ...response1.data,
          ...response2.data,
          ...response3.data,
          ...response4.data,
          ...response5.data,
        ];
      }
    }

    if (response5.has_more) {
      const _last = response5.data && response5.data[response5.data.length - 1];
      const _lastId = _last && _last.id;
      response6 = await _getBillCustomer(BASE_URL, {
        limit: 100,
        starting_after: _lastId,
      });
      if (response6.data) {
        response = [
          ...response1.data,
          ...response2.data,
          ...response3.data,
          ...response4.data,
          ...response5.data,
          ...response6.data,
        ];
      }
    }

    return response;
  };
}

export function getBillCustomer(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/customer`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createBillCustomer(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/customer`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getBillPrice(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/price`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getBillCoupon(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/coupon`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getBillTaxrate(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/taxrate`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getBillSubscription(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/subscription`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getBillPayment(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/payment`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function getBillInvoice(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/invoice`,
      method: "get",
      params,
    })
      .then((response) => {
        const _response = _get(response, "data", []);
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}

export function createBillInvoice(params = {}) {
  return (dispatch, getState) => {
    const BASE_URL = _get(getState(), "appData.BASE_URL");
    return axios({
      url: `${BASE_URL}/admin/bill/invoice`,
      method: "post",
      data: params,
    })
      .then((response) => {
        const _response = _get(response, "data", {});
        return _response;
      })
      .catch((error) => {
        const message = _get(error, "response.data.errors[0].message");
        return message;
      })
      .then((response) => {
        return response;
      });
  };
}
