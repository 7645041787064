import React from "react";
import _get from "lodash/get";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@mui/material/Button";
import { formatCurrency } from "../../../utils/numberHelpers";
import { LABELS as _LABELS } from "../../../language";

const LABELS = _LABELS.settingPage;

const styles = (theme) => ({
  wrapper: {},
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export class AddBillInvoice extends React.Component {
  state = {};

  componentDidMount() {}

  handleInvoiceUrl = (e, item) => {
    e.preventDefault();
    window.open(item.hosted_invoice_url, "_blank");
  };

  render() {
    const { classes, constants } = this.props;
    const invoices = this.props.invoices || [];
    const isSmallScreen = window.innerWidth < 400;
    // console.log("invoices :: ", invoices);

    return (
      <Dialog
        fullScreen={isSmallScreen}
        fullWidth={true}
        maxWidth={"lg"}
        className={classes.wrapper}
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography>{LABELS.addInvoice}</Typography>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <h3>All Invoices</h3>

          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Duedate</TableCell>
                <TableCell>Detail</TableCell>
              </TableRow>
              {invoices.map((invoice) => {
                const amountPaid = invoice.amount_paid
                  ? parseFloat(invoice.amount_paid) / 100
                  : 0;
                const amountDue = invoice.amount_due
                  ? parseFloat(invoice.amount_due) / 100
                  : 0;
                const amountRemaining = invoice.amount_remaining
                  ? parseFloat(invoice.amount_remaining) / 100
                  : 0;
                const lines = invoice.lines.data || [];

                return (
                  <TableRow
                    key={invoice.number}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <a
                        style={{ color: "#009ce5", cursor: "pointer" }}
                        onClick={(e) => this.handleInvoiceUrl(e, invoice)}
                      >
                        {invoice.number}
                      </a>
                    </TableCell>
                    <TableCell style={{ textTransform: "capitalize" }}>
                      {invoice.status}
                    </TableCell>
                    <TableCell>
                      <div>Paid: {formatCurrency(amountPaid)}</div>
                      <div>Due: {formatCurrency(amountDue)}</div>
                      <div>Remaining: {formatCurrency(amountRemaining)}</div>
                    </TableCell>

                    <TableCell>{invoice.due_date}</TableCell>
                    <TableCell>
                      {lines.map((line) => {
                        const amount = line.amount
                          ? parseFloat(line.amount) / 100
                          : 0;
                        return (
                          <Grid key={line.id} container>
                            <Grid item xs={7}>
                              {line.description}
                            </Grid>
                            <Grid item xs={1}>
                              {line.quantity}
                            </Grid>
                            <Grid item xs={4}>
                              {formatCurrency(amount)}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(AddBillInvoice);
