import _get from "lodash/get";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { getAccountDetails } from "../../../actions/account/accountAdminActions";
import {
  getBillInvoice,
  getBillPayment,
  // getBillSubscription
} from "../../../actions/adminBillActions";
import AccountSubscription from "./AccountSubscription";
import AccountInvoice from "./AccountInvoice";
import AccountPayment from "./AccountPayment";

const styles = (theme) => ({
  paper: {
    margin: 10,
    overflow: "hidden",
  },
  contentWrapper: {
    // margin: "40px 16px",
    // marginTop: "15px",
  },
});

function AccountBilling(props) {
  const classes = props.classes;
  const accountId = _get(props, "match.params.accountId");
  const [accountInfo, setAccountInfo] = useState({});

  const [subscriptionInfo, setSubscriptionInfo] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({});

  async function getDetails() {
    const response = await props.getAccountDetails({
      accountId,
    });
    setAccountInfo(response);
  }

  useEffect(() => {
    getDetails();
  }, []);

  const accountName = _get(accountInfo, "account.name");
  const stripeBuss = _get(accountInfo, "account.stripeBuss");

  return (
    <>
      <Grid container>
        <Grid item md={12} xs={12}>
          <h3 style={{ padding: "0px 15px" }}>{accountName}</h3>
        </Grid>
      </Grid>
      {stripeBuss && (
        <Grid container>
          {/*<Grid item md={12} xs={12}>
            <AccountSubscription
              stripeBuss={stripeBuss}
              getBillSubscription={props.getBillSubscription}
            />
          </Grid>*/}
          <Grid item md={12} xs={12}>
            <AccountInvoice
              stripeBuss={stripeBuss}
              getBillInvoice={props.getBillInvoice}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <AccountPayment
              stripeBuss={stripeBuss}
              getBillPayment={props.getBillPayment}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  const userRoles = _get(state, "authData.profile.roles", []);
  const userScopes = _get(state, "authData.profile.scopes", []);

  let isStripeAdmin = false;
  let isStripe = _get(state, "appData.appConfig.isStripe", "OFF") === "ON";

  if (userRoles.includes("PARTNER")) {
    isStripe = false;
  }
  if (userScopes.includes("ADMIN_PAYMENT_SUPPORT") && isStripe) {
    isStripeAdmin = true;
  }
  return {
    isStripe,
    isStripeAdmin,
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    selectedAccount: _get(state, "accountData.selectedAccount", {}),
    accountAuthBusy: _get(state, "accountData.accountAuthBusy", false),
    selectedAccountData: _get(state, "accountData.selectedAccountData", {}),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAccountDetails,

      getBillInvoice,
      getBillPayment,
      // getBillSubscription
    },
    dispatch
  );

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AccountBilling)
);
