/* eslint-disable react-hooks/rules-of-hooks */
import { CircularProgress, Grid } from "@mui/material";
import _get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchBar from "../../../components/SearchBar";
import {
  getLocations,
  getLocationCount,
} from "../../../actions/locationActions";
import { formatDate, formatTime } from "../../../actions/momentActions";
import { TIMEZONE_ABB } from "../../../constants";
import { getCompassDirection } from "../../../utils/numberHelpers";

const STYLES = {
  mainDiv: {
    margin: "0 50px 0",
  },
  table: {
    border: "0.1px solid #ccc",
    width: "98%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: 13,
    textAlign: "left",
  },
  tr: {
    border: "0.2px solid #ccc",
    padding: "20px",
  },
  th: {
    textTransform: "capitalize",
    padding: 5,
  },
  td: {
    textTransform: "capitalize",
    padding: 5,
  },
  reportHead: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "10px",
  },
};

function TableRow(props) {
  const data = props.item || [];
  const idx = props.idx;

  if (idx === 0) {
    return null;
  }

  return (
    <tr style={STYLES.tr}>
      <td style={STYLES.td}>{`${data[0]}`}</td>
      <td style={STYLES.td}>{`${data[1]}`}</td>
      <td style={STYLES.td}>{`${data[2]}`}</td>
      <td style={STYLES.td}>{`${data[3]}`}</td>
      <td>{`${data[4]}`}</td>
      <td>{`${data[5]}`}</td>
      <td>{`${data[6]}`}</td>
      <td>{`${data[7]}`}</td>
      <td>{`${data[8]}`}</td>
      <td>{`${data[9]}`}</td>
    </tr>
  );
}

function GpsDetail() {
  const dispatch = useDispatch();
  const timezone = useSelector((state) =>
    _get(state, "appData.defaultTimezone", "")
  );
  const [loading, setLoading] = useState(false);
  const [download, setDownload] = useState(false);
  const [locations, setLocations] = useState([]);
  const [formatedData, setFormatedData] = useState([]);
  const [fileName, setFileName] = useState("logs.csv");

  function formatData(items) {
    const data = [
      [
        "Driver",
        "Vehicle",
        "Location",
        "Date",
        "Time",
        "Timezone",
        "Speed",
        "Heading",
        "Latitude",
        "Longitude",
      ],
    ];
    for (let i = 0; i < items.length; i++) {
      const item = items[i] || {};
      const driver = item.driver || {};
      const vehicle = item.vehicle || {};
      const address = item.address || {};
      const telemetrics = item.telemetrics || {};
      const coordinates = item.coordinates || [];
      const speed = telemetrics.speedKm
        ? (telemetrics.speedKm * 0.621371).toFixed()
        : null;
      const date = dispatch(formatDate(item.time));
      const time = dispatch(formatTime(item.time));
      const latitude = parseFloat(coordinates[0].toFixed(6));
      const longitude = parseFloat(coordinates[1].toFixed(6));
      const itemObj = [
        `${driver.firstName} ${driver.lastName}`,
        `${vehicle.name}`,
        `${address.city} ${address.state}`,
        `${date}`,
        `${time}`,
        TIMEZONE_ABB[timezone],
        `${speed} mph`,
        getCompassDirection(item.heading),
        latitude,
        longitude,
      ];
      data.push(itemObj);
    }
    setFormatedData(data);
  }

  async function handleSearchChange({
    driverId,
    vehicleId,
    start,
    end,
    driver,
    vehicle,
  }) {
    const startDate = dispatch(formatDate(start));
    const endDate = dispatch(formatDate(end));
    const vehicleName = (vehicle && vehicle.name) || "";
    const _fileName = `${driver.firstName}_${driver.lastName}_${vehicleName}_${startDate}_to_${endDate}_gps.csv`.replace(
      /\s+/g,
      ""
    );
    setFileName(_fileName);

    let payload = {
      limit: 5000,
      skip: 0,
      to: end,
      from: start,
      sort: "created",
    };
    if (driverId) {
      payload.driverId = driverId;
    }
    if (vehicleId) {
      payload.vehicleId = vehicleId;
    }

    setLoading(true);
    const response = await dispatch(getLocations(payload));

    setDownload(true);
    setLoading(false);
    formatData(response);
  }

  const handleDownload = () => {
    // Convert array to CSV string
    const csvContent =
      "data:text/csv;charset=utf-8," +
      formatedData.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV data
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <Grid sx={{ flexGrow: 1 }} container spacing={2}>
        <Grid item xs={12}>
          <SearchBar
            options={{
              isEqp: true,
              isTeleEvent: false,
              isDriver: true,
              isStartDate: true,
              isEndDate: true,
              isTime: true,
              isTimeperiod: true,
              isDownload: download,
              isDownloadIcon: download,
            }}
            loading={loading}
            onSearch={handleSearchChange}
            onDownload={handleDownload}
          />
        </Grid>
        <Grid item xs={12}>
          <table style={STYLES.table}>
            <thead>
              <tr>
                <th style={STYLES.th}>Driver</th>
                <th style={STYLES.th}>Vehicle</th>
                <th style={STYLES.th}>Location</th>
                <th style={STYLES.th}>Date</th>
                <th style={STYLES.th}>Time</th>
                <th style={STYLES.th}>Timezone</th>
                <th style={STYLES.th}>Speed</th>
                <th style={STYLES.th}>Heading</th>
                <th style={STYLES.th}>Latitude</th>
                <th style={STYLES.th}>Longitude</th>
              </tr>
            </thead>
            <tbody>
              {formatedData.map((item, idx) => (
                <TableRow key={`id-${idx}`} item={item} idx={idx} />
              ))}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  );
}

export default GpsDetail;
