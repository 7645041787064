import React, { Component } from "react";
import _get from "lodash/get";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import CircularProgress from "@mui/material/CircularProgress";
import CurrentStatusTable from "./CurrentStatusTable";
import { getDriversRealTimeBeta } from "../../actions/driverActions";
import { formatDate, formatTime } from "../../actions/momentActions";
import { getEquipments } from "../../actions/equipmentActions";
import { getDcLiveview, getDcDetail } from "../../actions/dashcam/dashcamActions";
import {
  getMaintenance,
  getMaintenanceWarning,
} from "../../actions/maintenanceActions";
import SearchBar from "./SearchBar";
import RealTimeMap from "./Map/RealTimeMap";
import Checkout from "../Checkout";
import { LABELS } from "../../language";

const ALL_LABELS = LABELS.realtimePage;

class DailyLogs extends Component {
  state = {
    map: !this.props.isUnity,
    drivers: [],
    driversOrg: [],
    equipments: [],
  };

  async componentDidMount() {
    await this.getVehicles();
    await this.getDriversRealTimeBeta();
  }

  componentWillUnmount() {
    if (this.intervalId) {
      console.log(" ::::::: CLEARED ::::::: ", this.intervalId);
      clearInterval(this.intervalId);
    }
  }

  getDriversRealTimeBeta = async () => {
    const __driversOrg = await this.props.getDriversRealTimeBeta();
    const _driversOrg = __driversOrg && __driversOrg.map((item) => {
      // console.log("item = ", item);
      item.position = { lat: item.lat, lng: item.lng };
      return item;
    });

    function compare(a, b) {
      if ((a.lastContact || a.eventTime) > (b.lastContact || b.eventTime)) {
        return -1;
      }
      if ((a.lastContact || a.eventTime) < (b.lastConstact || b.eventTime)) {
        return 1;
      }
      return 0;
    }
    const driversOrg = _driversOrg && _driversOrg.sort(compare);

    this.setState({ drivers: driversOrg, driversOrg }, () => {
      this.getMaintenance();
    });
  };

  getVehicles = async () => {
    const payload = {
      skip: 0,
      limit: 500,
      dcExist: true,
      assetType: "VEHICLE",
      active: "true",
    };
    const equipments = await this.props.getEquipments(payload);
    this.setState({ equipments });
  };

  getMaintenance = async () => {
    const payload = {
      skip: 0,
      limit: 500,
      active: true,
    };
    await this.props.getMaintenance(payload);
    await this.props.getMaintenanceWarning();
  };

  onChange = (e) => {
    const query = e.target.value;
    const drivers =
      (this.state.driversOrg &&
        this.state.driversOrg.filter((item) => {
          const firstName = _get(item, "driver.firstName", "");
          const lastName = _get(item, "driver.lastName", "");
          const vehicleName = _get(item, "vehicle.name", "");
          const eventCode = item.eventCode || "";

          if (
            (firstName &&
              firstName.toUpperCase().includes(query.toUpperCase())) ||
            (lastName &&
              lastName.toUpperCase().includes(query.toUpperCase())) ||
            (eventCode &&
              eventCode.toUpperCase().includes(query.toUpperCase())) ||
            (vehicleName &&
              vehicleName.toUpperCase().includes(query.toUpperCase()))
          ) {
            return true;
          }
          return false;
        })) ||
      [];
    this.setState({ drivers });
  };

  startPooling = () => {
    this.getDriversRealTimeBeta();
  };

  onRefresh = () => {
    // this.setState({ loading: true });
    this.startPooling();
  };

  render() {
    const { map, drivers = [], driversOrg, equipments, loading } = this.state;
    const { formatDate, formatTime } = this.props;

    return (
      <Grid container>
        <Checkout />
        {!map && (
          <>
            <Grid item xs={12} sm={12} md={10}>
              <SearchBar
                onChange={this.onChange}
                map={map}
                LABELS={ALL_LABELS}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} style={{ textAlign: "right" }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  this.setState({ map: !map });
                }}
              >
                {map ? ALL_LABELS.table : ALL_LABELS.map}
              </Button>
              <IconButton
                aria-label="refresh"
                color="primary"
                onClick={this.onRefresh}
              >
                <span
                  style={{
                    minHeight: 30,
                    minWidth: 30,
                    marginTop: 7,
                  }}
                >
                  {loading ? <CircularProgress size={20} /> : <RefreshIcon />}
                </span>
              </IconButton>
            </Grid>
          </>
        )}
        {loading ? (
          <Grid
            item
            xs={12}
            sm={12}
            style={{ textAlign: "center", marginTop: "20px" }}
          >
            <CircularProgress />
          </Grid>
        ) : (
          <>
            {map ? (
              <RealTimeMap
                drivers={drivers}
                formatDate={formatDate}
                formatTime={formatTime}
                accountBaseUrl={this.props.accountBaseUrl}
                adminKey={this.props.adminKey}
                loading={loading}
                onRefresh={this.onRefresh}
                equipments={equipments}
                showTable={() => {
                  this.setState({ map: false });
                }}
                getRealTime={this.getDriversRealTimeBeta}
                search={this.onChange}
                getDcLiveview={this.props.getDcLiveview}
                getDcDetail={this.props.getDcDetail}
                navigateToPage={(_url) => this.props.history.push(_url)}
                LABELS={ALL_LABELS}
                isSmartelds={this.props.isSmartelds}
              />
            ) : (
              <Grid item xs={12} sm={12} md={12}>
                <CurrentStatusTable
                  driverData={drivers}
                  driversOrg={driversOrg}
                  LABELS={ALL_LABELS}
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: _get(state, "logData.logBusy", false),
    logs: _get(state, "logData.logs", []),
    driverRealTime: _get(state, "driverData.driverRealTime", []),
    accountBaseUrl: _get(state, "authData.accountBaseUrl"),
    updateFrequency: _get(
      state,
      "authData.profile.accountProperties.updateFrequency",
      5000
    ),
    adminKey: _get(state, "authData.profile.adminId"),
    isUnity: _get(state, "appData.isUnity", false),
    isSmartelds: _get(state, "appData.isSmartelds", false),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getEquipments,
      formatDate,
      formatTime,
      getDriversRealTimeBeta,
      getDcLiveview,
      getDcDetail,
      getMaintenance,
      getMaintenanceWarning,
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyLogs)
);
