import _get from "lodash/get";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import SearchBar from "../../../components/SearchBar";
import { getOnlyDriverLogs } from "../../../actions/logActions";
import { formatDate, formatTime } from "../../../actions/momentActions";

const STYLES = {
  mainDiv: {
    margin: "0 50px 0",
  },
  table: {
    marginTop: 50,
    border: "0.1px solid #ccc",
    width: "98%",
    borderCollapse: "collapse",
    margin: "auto",
    fontSize: 13,
    textAlign: "left",
  },
  tr: {
    border: "0.2px solid #ccc",
    padding: "20px",
  },
  th: {
    textTransform: "capitalize",
    padding: 5,
  },
  td: {
    textTransform: "capitalize",
    padding: 5,
  },
  reportHead: {
    textAlign: "center",
    fontSize: "20px",
    marginBottom: "10px",
  },
};

function TableRow(props) {
  const data = props.item || [];
  const idx = props.idx;

  if (idx === 0) {
    return null;
  }

  return (
    <tr style={STYLES.tr}>
      <td style={STYLES.td}>{`${data[0]}`}</td>
      <td style={STYLES.td}>{`${data[1]}`}</td>
      <td style={STYLES.td}>{`${data[2]}`}</td>
      <td style={STYLES.td}>{`${data[3]}`}</td>
      <td>{`${data[4]}`}</td>
      <td>{`${data[5]}`}</td>
    </tr>
  );
}

function Report(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isDownload, setDownload] = useState(false);
  const [fileName, setFileName] = useState("logs.csv");
  const [logs, setLogs] = useState([]);
  const accountId = useSelector((state) => _get(state, "authData.accountId"));

  function formatData(_items) {
    const data = [
      [
        "Date",
        "Status",
        "Location",
        "Odometer",
        "Vehicle",
        "Trailer",
      ],
    ];

    const items = _items.filter((item) => {
      return (
        item.recordStatus === "ACTIVE" &&
        (item.eventSubType === "status" ||
          item.eventType === "POWERUP_SHUTDOWN" ||
          item.eventType === "LOGIN_LOGOUT")
      );
    });

    let prevStatus = "";
    for (let i = 0; i < items.length; i++) {
      const item = items[i] || {};
      const driver = item.driver || {};
      const vehicle = item.vehicle || {};
      const address = item.address || {};
      const telemetrics = item.telemetrics || {};
      const coordinates = item.coordinates || [];
      const date = dispatch(formatDate(item.eventTime));
      const time = dispatch(formatTime(item.eventTime));
      let dutyStatus = item.eventCode;

      if (item.eventSubType === "location") {
        dutyStatus = "LOCATION";
      }
      if (item.eventSubType === "powerDown") {
        dutyStatus = "POWER DOWN";
      }
      if (item.eventSubType === "powerUp") {
        dutyStatus = "POWER UP";
      }
      if (item.eventSubType === "logIn") {
        dutyStatus = "LOGIN";
      }
      if (item.eventSubType === "logOut") {
        dutyStatus = "LOGOUT";
      }

      if (prevStatus !== dutyStatus) {
        const itemObj = [
          `${date} ${time}`,
          `${dutyStatus}`,
          `${item.location}`.replace(/,/g, ' '),
          `${item.odometer}`,
          `${vehicle.name}`,
          `${item.trailer}`,
        ];

        data.push(itemObj);
      }
      prevStatus = dutyStatus;
    }
    setLogs(data);
  }

  async function handleSearchChange({
    driverId,
    vehicleId,
    start,
    end,
    driver,
  }) {
    const startDate = dispatch(formatDate(start));
    const endDate = dispatch(formatDate(end));
    const _fileName = `${driver.firstName}_${driver.lastName}_${startDate}_to_${endDate}.csv`.replace(
      /\s+/g,
      ""
    );
    setFileName(_fileName);

    let payload = {
      limit: 10000,
      skip: 0,
      from: start,
      to: end,
      accountId,
    };
    if (driverId) {
      payload.driverId = driverId;
    }

    setLoading(true);
    setLogs([]);
    const response = await dispatch(getOnlyDriverLogs(payload));

    setDownload(true);
    setLoading(false);
    formatData(response.driverStatus || []);
  }

  const handleDownload = () => {
    // Convert array to CSV string
    const csvContent =
      "data:text/csv;charset=utf-8," +
      logs.map((row) => row.join(",")).join("\n");

    // Create a Blob from the CSV data
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      style={{
        padding: "40px 10px 40px 40px",
      }}
    >
      <Grid container className={{}}>
        <Grid item xs={12}>
          <SearchBar
            options={{
              isEqp: false,
              isDriver: true,
              isStartDate: true,
              isEndDate: true,
              isTime: false,
              isTimeperiod: false,
              dl: true,
              isDownload: isDownload,
              isDownloadIcon: true,
            }}
            loading={loading}
            onSearch={handleSearchChange}
            onDownload={handleDownload}
          />
        </Grid>

        <Grid item xs={12}>
          <table style={STYLES.table}>
            <thead>
              <tr>
                <th style={STYLES.th}>Date</th>
                <th style={STYLES.th}>Status</th>
                <th style={STYLES.th}>Location</th>
                <th style={STYLES.th}>Odometer</th>
                <th style={STYLES.th}>Vehicle</th>
                <th style={STYLES.th}>Trailer</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((item, idx) => (
                <TableRow key={`id-${idx}`} item={item} idx={idx} />
              ))}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </div>
  );
}

export default Report;
