import _get from "lodash/get";
import React from "react";
import { Link } from "react-router-dom";
import Alert from "@mui/material/Alert";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GroupIcon from "@mui/icons-material/Group";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { withStyles } from "@mui/styles";
import {
  SM_LOGO_LINE,
  LOGO_UNITY,
  LOGO_EBS,
  LOGO_VS,
  LOGO_RE,
  SM_LOGO_BH,
  LOGO_AU,
  LOGO_RW,
  LOGO_NN
} from "../../utils/logos";
import Support from "./Support";
import WarningMessages from "./WarningMessages";
import { getAdminSupportQueueCount } from "../../actions/adminSupportActions";
import { LABELS as _LABELS } from "../../language";
import { getUnReadChats } from "../../actions/adminWhatsappActions";

const LABELS = _LABELS.adminPage;

const lightColor = "rgba(255, 255, 255, 0.7)";

const styles = (theme) => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: "none",
    color: lightColor,
    "&:hover": {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
  account: {
    textTransform: "uppercase",
    fontWeight: 400,
  },
});

export class Header extends React.Component {
  state = {
    isSupport: false,
    anchorEl: null,
  };

  async componentDidMount() {
    this.fetchData();
    this.interval = setInterval(this.fetchData, 30000);
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.interval);
  }

  fetchData = async () => {
    const adminView = this.props.adminView;

    try {
      if (adminView) {
        // await this.props.getAdminSupportQueueCount({});
        const response = await this.props.getUnReadChats({});
        if (!response) {
          console.log(" ******* CLEAR ******* ");
          clearInterval(this.interval);
        }
      }
    } catch (e) {}
  };

  logOut = () => {
    this.props.handleUserIconClick();
  };

  openMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const plan = this.props.plan;
    const adminView = this.props.adminView;
    const isSmartelds = this.props.isSmartelds;
    const isUnity = this.props.isUnity;
    const isEldBooks = this.props.isEldBooks;
    const isBluehorse = this.props.isBluehorse;
    const isVulcansols = this.props.isVulcansols;
    const isRadical = this.props.isRadical;
    const isEldNN = this.props.isEldNN;
    const isLive = this.props.isLive || false;
    const isApex = this.props.isApex;
    const isRoadwise = this.props.isRoadwise;
    const isSupport = this.state.isSupport || false;
    const isManager = this.props.isManager || false;
    const isPartner = this.props.isPartner || false;
    const isWarningMessages = this.state.isWarningMessages || false;
    const whatsappCount = this.props.whatsappCount;
    const queueCount = this.props.queueCount;
    const warnings = this.props.warnings || [];
    const warningCount = (Array.isArray(warnings) && warnings.length) || 0;
    const { classes, adminKey, account, profile } = this.props;
    let LOGO_URL = SM_LOGO_LINE;
    let LOGO_STYLES = { maxWidth: 150, maxHeight: 28, margin: "auto" };

    if (isUnity) {
      LOGO_URL = LOGO_UNITY;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 95,
        marginTop: -25,
        marginBottom: -25,
      };
    }
    if (isEldBooks) {
      LOGO_URL = LOGO_EBS;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 55,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }
    if (isBluehorse) {
      LOGO_URL = SM_LOGO_BH;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 55,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }
    if (isVulcansols) {
      LOGO_URL = LOGO_VS;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 55,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }
    if (isRadical) {
      LOGO_URL = LOGO_RE;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 45,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }
    if (isApex) {
      LOGO_URL = LOGO_AU;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 55,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }
    if (isRoadwise) {
      LOGO_URL = LOGO_RW;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 60,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }
    if (isEldNN) {
      LOGO_URL = LOGO_NN;
      LOGO_STYLES = {
        maxWidth: 150,
        margin: "auto",
        maxHeight: 60,
        marginTop: -25,
        marginBottom: -25,
        marginLeft: 25,
      };
    }

    let warningColor = "";

    const warningError = warnings.find((item) => item.code === "ERROR");

    if (warningError && warningError.code) {
      warningColor = "error";
    } else {
      const warningWarning = warnings.find((item) => item.code === "WARNING");

      if (warningWarning && warningWarning.code) {
        warningColor = "warning";
      } else {
        const warningUpcoming = warnings.find(
          (item) => item.code === "UPCOMING"
        );

        if (warningUpcoming && warningUpcoming.code) {
          warningColor = "primary";
        }
      }
    }

    return (
      <React.Fragment>
        {isSupport && (
          <Support
            account={account}
            profile={profile}
            open={isSupport}
            plan={plan}
            onClose={() => this.setState({ isSupport: false })}
          />
        )}
        {isWarningMessages && (
          <WarningMessages
            items={warnings}
            open={isWarningMessages}
            onClose={() => this.setState({ isWarningMessages: false })}
          />
        )}
        <AppBar color="primary" position="sticky" elevation={0}>
          <Toolbar>
            <Grid container spacing={0} alignItems="center">
              <Grid item style={{ display: "flex" }}>
                <Hidden>
                  <img src={LOGO_URL} alt={"Elds"} style={LOGO_STYLES} />
                </Hidden>
              </Grid>

              <Grid item xs>
                {(isSmartelds || isEldBooks) &&
                adminView &&
                (queueCount || whatsappCount) ? (
                  <Alert
                    severity="warning"
                    style={{
                      maxWidth: 400,
                      margin: "auto",
                      paddingBottom: 0,
                      paddingTop: 0,
                    }}
                  >
                    <>
                      {queueCount ? (
                        <>
                          <Link
                            to={`/dashboard/support/queue`}
                            style={{ textDecoration: "none" }}
                          >
                            {`${queueCount}`} {LABELS.callInQueue}
                          </Link>
                          <br />
                        </>
                      ) : null}

                      {whatsappCount ? (
                        <Link
                          to={`/dashboard/whatsapp`}
                          style={{ textDecoration: "none" }}
                        >
                          {`${whatsappCount}`} {" unread message"}
                        </Link>
                      ) : null}
                    </>
                  </Alert>
                ) : null}
              </Grid>

              {isLive ? null : (
                <Grid item className={classes.account}>
                  {account.name}
                </Grid>
              )}
              {(isManager || adminKey) && (
                <Grid item>
                  <Tooltip title={LABELS.notification}>
                    <IconButton
                      color="inherit"
                      onClick={() => {
                        this.setState({ isWarningMessages: true });
                      }}
                    >
                      <Badge badgeContent={warningCount} color={warningColor}>
                        <NotificationsActiveIcon color={warningColor} />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              {adminKey && (
                <Grid item>
                  <Tooltip title={LABELS.AllAccounts}>
                    <IconButton
                      color="inherit"
                      onClick={this.props.handleAppAccount}
                    >
                      <GroupIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}

              <Grid item>
                {adminKey && !isPartner && isSmartelds && (
                  <Tooltip title={LABELS.CustomerSupportPackage}>
                    <IconButton
                      onClick={() => this.setState({ isSupport: true })}
                      color="inherit"
                      className={classes.iconButtonAvatar}
                    >
                      <HeadsetMicIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title="Menu">
                  <IconButton
                    onClick={this.openMenu}
                    color="inherit"
                    className={classes.iconButtonAvatar}
                  >
                    <MenuIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  id="basic-menu"
                  anchorEl={this.state.anchorEl}
                  open={!!this.state.anchorEl}
                  onClose={this.handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={this.logOut}
                  >{`${LABELS.signOut}`}</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}
Header.propTypes = {
  classes: PropTypes.object.isRequired,
  onDrawerToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    isAdmin: _get(state, "authData.isAdmin", false),
    isSuperAdmin: _get(state, "authData.isSuperAdmin", false),
    isPartner: _get(state, "authData.isPartner", false),
    profile: _get(state, "authData.profile", {}),
    account: _get(state, "authData.profile.account", {}),
    isLive: _get(state, "appData.isLive", false),
    isSmartelds: _get(state, "appData.isSmartelds", false),
    isUnity: _get(state, "appData.isUnity", false),
    isEldBooks: _get(state, "appData.isEldBooks", false),
    isBluehorse: _get(state, "appData.isBluehorse", false),
    isVulcansols: _get(state, "appData.isVulcansols", false),
    isRadical: _get(state, "appData.isRadical", false),
    isApex: _get(state, "appData.isApex", false),
    isRoadwise: _get(state, "appData.isRoadwise", false),
    isEldNN: _get(state, "appData.isEldNN", false),
    plan: _get(state, "authData.profile.plan.plan", {}),
    adminView: !_get(state, "authData.profile.account.name"),
    queueCount: _get(state, "adminSupportData.queueCount"),
    warnings: _get(state, "maintenanceData.warnings", []),
    isManager: _get(state, "authData.isManager"),
    whatsappCount: _get(state, "whatsappData.count"),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ getAdminSupportQueueCount, getUnReadChats }, dispatch);

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);
